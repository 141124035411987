import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import Layout from '../components/Layout.js';
import RESOURCE from '../resource';
import {
  imageWrapper,
  introduction,
  contentContainer,
} from '../styles/about.module.css';

export default function AboutPage() {
  return (
    <Layout title="About this site" description="More info about this site">
      <br />
      <div className={contentContainer}>
        <div className={imageWrapper}>
          <StaticImage
            placeholder="blurred"
            src="../images/ramie-fabric3.jpg"
            alt="Dried ramie"
          />
          <StaticImage
            placeholder="blurred"
            src="../images/ramie-fabric2.jpg"
            alt="Degummed ramie in process"
          />
          <StaticImage
            placeholder="blurred"
            src="../images/ramie-fabric.jpg"
            alt="Ramie top in process"
          />
        </div>
        <div className={introduction}>
          <p>
            {RESOURCE.INTRODUCTION}
            <br />
            <br />
            <span>info@linjiang-ramie.com</span>
          </p>
        </div>
      </div>
    </Layout>
  );
}
